import { Dispatch, SetStateAction } from "react";
import moment from "moment";
import first from "lodash/first";
import last from "lodash/last";
import size from "lodash/size";

import {
  GridValueGetterParams,
  GridValueFormatterParams,
  GridValueSetterParams,
  GridFilterItem,
  GridColDef,
} from "@mui/x-data-grid-pro";

import { orderTypeEnumToNameMap } from "globals/utils/enumMaps";
import { FarmRelationshipEnum, Trip } from "types";
import { convertMinutesToHoursMinutes, currency } from "globals/utils/helpers";
import { getTripIconAndCategoryType } from "pages/requests/utils/getTripIconAndCategoryType";
import { renderEditCell } from "./dataGridEditRenderer";
import {
  DispatchStatusEditColumn,
  AlertsEditColumn,
  DriverEditColumn,
  TotalAmountEditColumn,
  PickupEditColumn,
  DropOffEditColumn,
} from "../components/editCellComponents";
import {
  AffiliateColumn,
  AlertsColumn,
  BookingContactColumn,
  CompanyColumn,
  DispatchStatusColumn,
  DriverColumn,
  DriverNoteColumn,
  DropOffLocationColumn,
  DropOffTimeColumn,
  DurationColumn,
  PassengerColumn,
  PickUpLocationColumn,
  PickUpTimeColumn,
  PaidStatusColumn,
  TripNotesColumn,
  ConfNumberColumnV2,
} from "../components";
import { getPassenger } from "globals/utils/passenger";
import { convertUtcDatetimeToSpecificTimezone } from "globals/utils/convertUtcDatetimeToSpecificTimezone";
import DriverFilterInput from "../components/DriverFilterInput";
import StatusFilterInput from "../components/StatusFilterInput";
import VehicleFilterInput from "../components/VehicleFilterInput";
import AffiliateFilterInput from "../components/AffiliateFilterInput";
import CompanyFilterInput from "../components/CompanyFilterInput";
import CloseStatusFilterInput from "../components/CloseStatusFilterInput";
import PaidStatusFilterInput from "../components/PaidStatusFilterInput";
import VehicleColumn from "../components/VehicleColumn";
import VehicleEditColumn from "../components/editCellComponents/VehicleEditColumn";
import TripClassificationFilterInput from "../components/TripClassificationFilterInput";
import TripTypeFilterInput from "../components/TripTypeFilterInput";
import BookingContactFilterInput from "../components/BookingContactFilterInput";

type TripValueGetterParams = GridValueGetterParams<Trip, Trip>;

const defaults = {
  filterable: true,
  sortable: true,
  hide: false,
  width: 140,
};

const datetimeSortComparator = (
  a: { datetimeUtc: Date; timezoneId: string },
  b: { datetimeUtc: Date; timezoneId: string }
) => {
  const aDateTime = convertUtcDatetimeToSpecificTimezone(
    a.datetimeUtc,
    a.timezoneId
  );
  const bDateTime = convertUtcDatetimeToSpecificTimezone(
    b.datetimeUtc,
    b.timezoneId
  );
  return aDateTime.diff(bDateTime);
};

export enum EditableField {
  Vehicle = "vehicle",
  PickupTime = "pickupTime",
  DropoffTime = "dropoffTime",
  StatusSlug = "statusSlug",
  DriverRow = "driverRow",
  DriverNote = "driverNote",
  Alerts = "alerts",
  TotalAmount = "totalAmt",
}

// Helper function for filter application
const commonContainsApplyFilterFn = (filterItem: GridFilterItem) => {
  if (
    !filterItem.value ||
    (Array.isArray(filterItem.value) && filterItem.value.length === 0)
  ) {
    return null;
  }
  return (params: { value: string | null }): boolean => {
    if (params.value === null) {
      return false;
    }
    const rowValue = params.value.toUpperCase();
    const searchValues = Array.isArray(filterItem.value)
      ? filterItem.value.map((item: { id: string; name: string }) =>
          item.name.toUpperCase()
        )
      : [filterItem.value];
    return searchValues.includes(rowValue);
  };
};

export const dataGridDefaultConfigV2 = (
  setSaveIndicatorState: Dispatch<
    SetStateAction<"default" | "saved" | "loading" | "error">
  >,
  refetchTripsData: () => void
): GridColDef[] => {
  return [
    {
      ...defaults,
      headerName: "Conf. No.",
      field: "completeOrderNumber",
      valueGetter: ({ row }: TripValueGetterParams) =>
        `${row.request.orderNumber}-${row.tripNumber}`,
      renderCell: ConfNumberColumnV2,
    },

    {
      ...defaults,
      headerName: "Trip Status",
      field: EditableField.StatusSlug,
      valueGetter: ({ row }: TripValueGetterParams) =>
        row.routes[0].dispatchStatus,
      renderCell: DispatchStatusColumn,
      renderEditCell: renderEditCell(
        DispatchStatusEditColumn,
        setSaveIndicatorState
      ),
      editable: true,
      filterOperators: [
        {
          label: "Contains",
          value: "contains",
          getApplyFilterFn: commonContainsApplyFilterFn,
          InputComponent: StatusFilterInput,
        },
      ],
    },

    {
      ...defaults,
      headerName: "Order Type",
      field: "orderType",
      valueGetter: ({ row }: TripValueGetterParams) =>
        orderTypeEnumToNameMap[row.request.orderType],
    },

    {
      ...defaults,
      width: 200,
      headerName: "Passenger",
      field: "tripContact",
      valueGetter: ({
        row: { tempPassenger, contact, routes },
      }: TripValueGetterParams) => {
        const { farmRelationship } = routes[0];

        const isFarmee = farmRelationship === FarmRelationshipEnum.Farmee;
        const passenger = getPassenger(contact, tempPassenger, isFarmee);
        return passenger.name;
      },
      renderCell: PassengerColumn,
    },

    {
      ...defaults,
      width: 200,
      headerName: "Driver",
      field: EditableField.DriverRow,
      valueGetter: ({ row: { routes } }: TripValueGetterParams) =>
        routes[0].routeDriver?.driver.firstName
          ? `${routes[0].routeDriver.driver.firstName} ${
              routes[0].routeDriver?.driver.lastName || ""
            }`
          : null,
      valueSetter: (params: GridValueSetterParams) => {
        refetchTripsData();
        const driver = params.value;
        return { ...params.row, value: driver };
      },
      renderCell: DriverColumn,
      editable: true,
      renderEditCell: renderEditCell(DriverEditColumn, setSaveIndicatorState),
      filterOperators: [
        {
          label: "Contains",
          value: "contains",
          getApplyFilterFn: commonContainsApplyFilterFn,
          InputComponent: DriverFilterInput,
        },
      ],
    },

    {
      ...defaults,
      headerName: "Company",
      field: "company",
      valueGetter: ({ row }: TripValueGetterParams) => row.request.company,
      renderCell: CompanyColumn,
      filterOperators: [
        {
          label: "Contains",
          value: "contains",
          getApplyFilterFn: (filterItem: GridFilterItem) => {
            if (
              !filterItem.value ||
              (Array.isArray(filterItem.value) && filterItem.value.length === 0)
            ) {
              return null;
            }
            return (params: {
              value: { __typename: string; name: string; email: string } | null;
            }): boolean => {
              if (!params.value || !params.value.name) {
                return false;
              }
              const rowValue = params.value.name.toUpperCase();
              const searchValues = Array.isArray(filterItem.value)
                ? filterItem.value.map((item: { id: string; name: string }) =>
                    item.name.toUpperCase()
                  )
                : [filterItem.value];
              return searchValues.includes(rowValue);
            };
          },
          InputComponent: CompanyFilterInput,
        },
      ],
    },

    {
      ...defaults,
      headerName: "Vehicle",
      field: EditableField.Vehicle,
      valueGetter: ({ row }: TripValueGetterParams) =>
        row.routes[0]?.vehicle?.name || "",
      filterOperators: [
        {
          label: "Contains",
          value: "contains",
          getApplyFilterFn: commonContainsApplyFilterFn,
          InputComponent: VehicleFilterInput,
        },
      ],
      renderCell: VehicleColumn,
      editable: true,
      renderEditCell: renderEditCell(VehicleEditColumn, setSaveIndicatorState),
    },

    {
      ...defaults,
      filterable: false,
      headerName: "Date",
      field: "date",
      type: "date",
      valueGetter: ({ row }: TripValueGetterParams) =>
        first(row.stops).dateTime,
      valueFormatter: ({ value }: GridValueFormatterParams) =>
        moment.utc(value as Date).format("M/DD/YY"),
    },

    {
      ...defaults,
      filterable: false,
      headerName: "Pick-up Time",
      field: EditableField.PickupTime,
      type: "dateTime",
      valueGetter: ({ row }: TripValueGetterParams) => {
        return {
          datetimeUtc: first(row.stops).dateTime,
          timezoneId: first(row.stops).timezoneId,
        };
      },
      renderCell: PickUpTimeColumn,
      sortComparator: datetimeSortComparator,
      editable: true,
      renderEditCell: renderEditCell(PickupEditColumn, setSaveIndicatorState),
    },

    {
      ...defaults,
      filterable: false,
      headerName: "Drop-off Time",
      field: EditableField.DropoffTime,
      type: "dateTime",
      valueGetter: ({ row }: TripValueGetterParams) => {
        const pickup = first(row.stops);
        const dropoff = last(row.stops);
        const timezoneId = pickup.timezoneId;

        return dropoff.dateTime
          ? {
              datetimeUtc: dropoff.dateTime,
              timezoneId,
            }
          : {
              datetimeUtc: moment
                .utc(pickup.dateTime)
                .add(row.estimatedDuration, "minutes"),
              timezoneId,
            };
      },
      renderCell: DropOffTimeColumn,
      sortComparator: datetimeSortComparator,
      editable: true,
      renderEditCell: renderEditCell(DropOffEditColumn, setSaveIndicatorState),
    },

    {
      ...defaults,
      filterable: false,
      headerName: "Duration",
      field: "duration",
      valueGetter: ({ row }: TripValueGetterParams) =>
        convertMinutesToHoursMinutes(
          row.useTotalDuration ? row.totalDuration : row.estimatedDuration
        ),
      renderCell: DurationColumn,
    },

    {
      ...defaults,
      width: 300,
      headerName: "Pick-up",
      field: "pickupLocation",
      valueGetter: ({ row }: TripValueGetterParams) =>
        first(row.stops).location,
      renderCell: PickUpLocationColumn,
    },

    {
      ...defaults,
      filterable: false,
      headerName: "# Of Stops",
      field: "stopCount",
      type: "number",
      valueGetter: ({ row }: TripValueGetterParams) => size(row.stops) - 2,
    },

    {
      ...defaults,
      width: 300,
      headerName: "Drop-off",
      field: "dropoffLocation",
      valueGetter: ({ row }: TripValueGetterParams) =>
        last(row.stops)?.location,
      renderCell: DropOffLocationColumn,
    },

    {
      ...defaults,
      filterable: false,
      headerName: "PAX Count",
      field: "totalGroupSize",
      type: "number",
      valueGetter: ({ row }: TripValueGetterParams) => row.totalGroupSize,
    },

    {
      ...defaults,
      headerName: "Trip Classification",
      field: "tripClassification",
      valueGetter: ({ row: { routes } }: TripValueGetterParams) =>
        routes[0].farmRelationship === FarmRelationshipEnum.Farmee
          ? "Farm-In"
          : routes[0].farmRelationship === FarmRelationshipEnum.Farmor
          ? "Farm-Out"
          : "Standard",
      filterOperators: [
        {
          label: "Contains",
          value: "contains",
          getApplyFilterFn: commonContainsApplyFilterFn,
          InputComponent: TripClassificationFilterInput,
        },
      ],
    },

    {
      ...defaults,
      headerName: "Trip Type",
      field: "tripCategory",
      valueGetter: ({ row }: TripValueGetterParams) =>
        getTripIconAndCategoryType({
          tripCategory: row.tripCategory,
          roundTripVariant: row.roundTripVariant,
        }).text.replace(/\n/g, " "),
      filterOperators: [
        {
          label: "Contains",
          value: "contains",
          getApplyFilterFn: commonContainsApplyFilterFn,
          InputComponent: TripTypeFilterInput,
        },
      ],
    },

    {
      ...defaults,
      headerName: "Trip Notes",
      field: "tripNote",
      valueGetter: ({ row }: TripValueGetterParams) => row.note,
      renderCell: TripNotesColumn,
    },

    {
      ...defaults,
      headerName: "Driver Notes",
      field: EditableField.DriverNote,
      valueGetter: ({ row: { routes } }: TripValueGetterParams) =>
        routes[0].driverNote,
      renderCell: DriverNoteColumn,
      editable: true,
    },

    {
      ...defaults,
      filterable: false,
      headerName: "Alerts",
      field: EditableField.Alerts,
      renderCell: AlertsColumn,
      renderEditCell: renderEditCell(AlertsEditColumn, setSaveIndicatorState),
      editable: true,
    },

    {
      ...defaults,
      headerName: "Booking Contact",
      field: "bookingContact",
      valueGetter: ({ row }: TripValueGetterParams) => {
        const bookingContact = row.request.bookingContact;

        return `${bookingContact?.firstName || ""} ${
          bookingContact?.lastName || ""
        }`;
      },
      renderCell: BookingContactColumn,
      filterOperators: [
        {
          label: "Contains",
          value: "contains",
          getApplyFilterFn: commonContainsApplyFilterFn,
          InputComponent: BookingContactFilterInput,
        },
      ],
    },

    {
      ...defaults,
      filterable: false,
      headerName: "Total Amount",
      field: EditableField.TotalAmount,
      type: "number",
      valueGetter: ({ row }: TripValueGetterParams) => {
        const route = first(row.routes);

        const totalAmount =
          route.farmRelationship === FarmRelationshipEnum.Farmee
            ? route.farmeePricing?.totalAmt
            : route.pricing?.totalAmt;

        return totalAmount || 0;
      },
      valueFormatter: ({ value }) => {
        return currency(Number(value));
      },
      editable: true,
      renderEditCell: renderEditCell(
        TotalAmountEditColumn,
        setSaveIndicatorState
      ),
    },

    {
      ...defaults,
      headerName: "Affiliate",
      field: "affiliate",
      valueGetter: ({ row: { routes } }: TripValueGetterParams) =>
        routes[0].farmAffiliate?.operatorName || "",
      renderCell: AffiliateColumn,
      filterOperators: [
        {
          label: "Contains",
          value: "contains",
          getApplyFilterFn: commonContainsApplyFilterFn,
          InputComponent: AffiliateFilterInput,
        },
      ],
    },

    {
      ...defaults,
      headerName: "Close Status",
      field: "closeStatus",
      valueGetter: ({ row }: TripValueGetterParams) =>
        row.closedAt ? "Closed" : "Open",
      filterOperators: [
        {
          label: "Equals",
          value: "equals",
          getApplyFilterFn: (filterItem: GridFilterItem) => {
            if (!filterItem.value) {
              return null;
            }
            return (params: { value: string | null }): boolean => {
              if (params.value === null) {
                return false;
              }
              return params.value === filterItem.value;
            };
          },
          InputComponent: CloseStatusFilterInput,
        },
      ],
    },

    {
      ...defaults,
      headerName: "Paid Status",
      field: "paidStatus",
      valueGetter: ({ row }: TripValueGetterParams) =>
        row.amountDue > 0 ? "Not Paid" : "Paid",
      renderCell: PaidStatusColumn,
      filterOperators: [
        {
          label: "Equals",
          value: "equals",
          getApplyFilterFn: (filterItem: GridFilterItem) => {
            if (!filterItem.value) {
              return null;
            }
            return (params: { value: string | null }): boolean => {
              if (params.value === null) {
                return false;
              }
              return params.value === filterItem.value;
            };
          },
          InputComponent: PaidStatusFilterInput,
        },
      ],
    },
  ];
};
