import React, { useState } from "react";
import { Helmet } from "react-helmet";
import { useMutation, useQuery } from "@apollo/client";
import moment from "moment";
import flatMap from "lodash/flatMap";

import { Box, Button, Typography } from "@mui/material";

import { grayLight, white } from "../../design-system/colors";
import EntityHeader from "../../components/common/EntityHeader";
import { useAnalytics, useScreenSize, useSnackbar } from "../../globals/hooks";
import DateFilter from "../../components/common/DateFilter";
import { currency } from "../../globals/utils/helpers";
import DriverPayoutList from "../../components/driverPayout/list/DriverPayoutList";
import DriverPayoutSummary from "../../components/driverPayout/DriverPayoutSummary";
import {
  CREATE_DRIVER_PAYOUT_MUTATION,
  LOAD_POTENTIAL_DRIVER_PAYOUT_QUERY,
} from "../../globals/graphql/driverPayout.graphql";
import MoovsDialog from "../../components/MoovsDialog";
import GQLQueryStatusIndicator from "../../components/GQLQueryStatusIndicator";
import EmptyList from "../../components/globals/EmptyList";
import { DateRange } from "components/common/DateFilter/types";
import { useCustomDateRange } from "components/common/hooks/useCustomDateRange";

function DriverPayoutPage() {
  // hooks
  const snackbar = useSnackbar();
  const { isMobileView, isSmallTabletView } = useScreenSize();
  const { track } = useAnalytics();
  const { storedDateRange } = useCustomDateRange({ mode: "driver-payout" });

  // state
  const [dialogSubmitDisabled, setDialogSubmitDisabled] = useState(false);
  const [payoutDialogOpen, setPayoutDialogOpen] = useState(false);
  const [dateRange, setDateRange] = useState<DateRange>(storedDateRange);

  // derived state
  const [startDate, endDate] = dateRange;

  // queries
  const {
    data: driverPayoutData,
    error: driverPayoutError,
    refetch: driverPayoutRefetch,
    networkStatus: driverPayoutNetworkStatus,
    loading: driverPayoutLoading,
  } = useQuery(LOAD_POTENTIAL_DRIVER_PAYOUT_QUERY, {
    variables: {
      ...(startDate && { startDate: moment(startDate).format("YYYY-MM-DD") }),
      ...(endDate && { endDate: moment(endDate).format("YYYY-MM-DD") }),
    },
    fetchPolicy: "network-only",
  });

  const { loadPotentialDriverPayouts: driverPayout } = driverPayoutData || {};

  // mutations
  const [createDriverPayout] = useMutation(CREATE_DRIVER_PAYOUT_MUTATION, {
    refetchQueries: [
      {
        query: LOAD_POTENTIAL_DRIVER_PAYOUT_QUERY,
        variables: {
          ...(startDate && {
            startDate: moment(startDate).format("YYYY-MM-DD"),
          }),
          ...(endDate && { endDate: moment(endDate).format("YYYY-MM-DD") }),
        },
      },
    ],
    onCompleted(data) {
      snackbar.success(`Successfully created driver payout!`);

      track("create_driver_payout");

      setDialogSubmitDisabled(false);
      setPayoutDialogOpen(false);
    },
    onError(error) {
      snackbar.error("Error creating driver payout");
      setDialogSubmitDisabled(false);
    },
  });

  // event handlers
  const handleClickPayout = () => {
    setDialogSubmitDisabled(true);

    const flattenedRouteIds = flatMap(
      driverPayout?.driverPayables,
      (driverPayable) => driverPayable.operatorRoutes.map(({ id }) => id)
    );

    createDriverPayout({
      variables: {
        input: {
          amount: driverPayout?.totalAmt,
          routeIds: flattenedRouteIds,
        },
      },
    });
  };


  const totalAmt = driverPayout?.totalAmt;
  const payoutDisabled =
    !driverPayout?.driverPayables || !driverPayout?.driverPayables.length;

  return (
    <>
      <Helmet>
        <title>Payables | Moovs</title>
      </Helmet>

      <Box justifyContent="center" width="100%">
        <Box
          position="sticky"
          top={isMobileView ? "56px" : "78px"}
          zIndex={10}
          width="100%"
          bgcolor={white}
          pb={1}
          borderBottom={`1px solid ${grayLight}`}
        >
          <Box maxWidth="900px" margin="auto">
            <EntityHeader
              title="Payables"
              dateFilter={
                <DateFilter
                  mode="driver-payout"
                  isMobileView={isSmallTabletView}
                  dateRange={dateRange}
                  setDateRange={setDateRange}
                />
              }
            />
            {/* Payout Summary */}
            <Box mt={2}>
              <DriverPayoutSummary driverPayout={driverPayout} />
            </Box>
          </Box>
        </Box>

        {driverPayoutLoading ? (
          <GQLQueryStatusIndicator
            name="Driver Payables"
            data={driverPayoutData}
            loading={driverPayoutLoading}
            error={driverPayoutError}
            networkStatus={driverPayoutNetworkStatus}
            refetch={driverPayoutRefetch}
          />
        ) : (
          <Box maxWidth="900px" margin="auto">
            {driverPayout?.driverPayables.length ? (
              <>
                {/* Payout List */}
                <Box mx={isSmallTabletView ? 2 : 3} mt={1}>
                  <DriverPayoutList
                    driverPayables={driverPayout?.driverPayables}
                  />
                </Box>
                {/* Submit Button */}
                <Box mx={3} mt={5} display="flex" justifyContent="flex-end">
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={() => setPayoutDialogOpen(true)}
                    disabled={payoutDisabled}
                  >
                    Payout {currency(totalAmt)}
                  </Button>
                </Box>
              </>
            ) : (
              <Box
                height="calc(100vh - 226px)"
                display="flex"
                justifyContent="center"
                alignItems="center"
              >
                <EmptyList
                  title="No payables due"
                  copy="Driver payables will show up here after their trips have been closed out."
                />
              </Box>
            )}
          </Box>
        )}
      </Box>
      <MoovsDialog
        size="xs"
        open={payoutDialogOpen}
        onClose={() => setPayoutDialogOpen(false)}
        dialogTitle="Payout"
        onAccept={handleClickPayout}
        hideTopBorder={true}
        acceptButtonText="Pay"
        closeButtonText="Cancel"
        acceptDisabled={dialogSubmitDisabled}
      >
        <Box mb={3}>
          <Typography variant="body2">
            Are you sure you want to payout {currency(totalAmt)}?
          </Typography>
        </Box>
      </MoovsDialog>
    </>
  );
}

export default DriverPayoutPage;
